import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    @ViewChild('username_login') username: ElementRef;
    @ViewChild('password_login') password: ElementRef;

    public showPasswordText: boolean=false;

    public numero_WhatsAppMessage=environment.numero_WhatsApp;
    public detailUser: string[];

    public menuWeb: any=[];
    public menuWeb_Secondary: any=[];
    router: Router;

    constructor(
        private crudService: CrudService,
        router: Router
    ) {
        this.router = router;
    }

    ngOnInit(): void {
        //mi recupero il dettaglio dell'user dopo il LOGIN
        this.detailUser=JSON.parse(localStorage.getItem('user_id'));

        if(this.detailUser) {
            let idUser=this.detailUser['id_user'];
            let idCorsista=this.detailUser['id_corsista']
        }

        //mi ricavo la lista delle categorie del menù
        this.getElencoSottoCategoriaMenu();
        this.getElencoSottoCategoriaMenuSecondary();
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }


    showLogin(){
        /*
        this.crudService.api_getLoginClientAPP("NFA0113").subscribe(responseData => {
            console.log(responseData);
        });
        */

        Swal.fire({
            html: '<div class="main-banner-content mt-2">\
                    <h3 style="font-family:var(--fontFamily); color:var(--blackColor); margin-bottom: 0px; line-height: 1.3; font-size: 24px; font-weight: 800;">\
                        Accedi ai tuoi<span style="color:var(--mainColor)"> corsi</span>\
                    </h3>\
                    <span style="color: var(--optionalColor); font-size: var(--fontSize);">\
                        effettua il login per accedere ai tuoi corsi\
                    </span><br>\
                    <div class="form-group pt-3" align="left">\
                        <input [(ngModel)]="playerName" type="text" class="form-control" id="username" placeholder="inserisci il tuo username">\
                    </div>\
                    <div class="form-group mt-3 mb-3">\
                        <input [type]="showPasswordText ? "text" : "password" #password_login id="password_login" class="form-control" placeholder="inserisci la tua password">\
                        <img id="buttonPsw1" onclick="showPassword();" *ngIf="showPasswordText==false" src="assets/img/view-password.png" style="height: 26px; margin-top: -38px; float: right; margin-right: 20px; cursor: pointer; z-index:99999">\
                        <img id="buttonPsw2" onclick="showPassword();" *ngIf="showPasswordText==true" src="assets/img/hide-password.png" style="height: 26px; margin-top: -38px; float: right; margin-right: 20px; cursor: pointer; z-index:99999">\
                    </div>\
                    <span style="color: var(--optionalColor); margin-bottom: 15px; font-size: var(--fontSize);">\
                        Non sei ancora registrato?\
                        <a href="/contatti" style="font-weight:800; cursor:pointer"> Registrati</a>\
                    </span><br>\
                    <span style="color: var(--optionalColor); margin-bottom: 15px; font-size: var(--fontSize);">\
                        Password dimenticata?\
                        <a href="/reset-password" style="font-weight:800; cursor:pointer"> Reset Password</a>\
                    </span>\
                   </div>',
            showCancelButton: true,
            confirmButtonColor: 'var(--mainColor)',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Effettua il login!',
            cancelButtonText: 'Annulla',
            width: '400px',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
                Swal.showLoading();
                let username=(document.getElementById('username')  as HTMLInputElement).value;
                let password=(document.getElementById('password')  as HTMLInputElement).value;
                this.checkLogin(username, password);
            } else if(result.isDismissed) {
            }
          });

          document.getElementById('buttonPsw1').onclick = function(){
        }
    }

    resetPassword() {
        alert("ok");
    }

    getElencoSottoCategoriaMenu() {
        this.crudService.api_get_ElencoSottoCategorieWeb().subscribe(responseData => {
            this.menuWeb=responseData;
        });
    }

    getElencoSottoCategoriaMenuSecondary() {
        this.crudService.api_get_ElencoSottoCategorieWeb_Secondary().subscribe(responseData => {
            this.menuWeb_Secondary=responseData;
        });
    }

    checkLogin(username, password){
        this.crudService.api_Login(username, password).subscribe(responseData => {
            //console.log(responseData);
            if(responseData['status']) {
                localStorage.setItem("user_id",JSON.stringify(responseData));
                document.getElementById('btnLogin').style.backgroundColor="#000";

                Swal.fire({
                    title:'Account Verificato!',
                    text: 'login effettuato con successo, accedi ai tuoi corsi.',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: 'var(--mainColor)',
                    cancelButtonColor: '#dadada',
                    confirmButtonText: 'Iniziamo',
                    allowOutsideClick: false,
                 }).then((result) => {
                    if (result.isConfirmed) {
                        location.href="/elenco-corsi/i-miei-corsi";
                    }
                });
            }
            else {
                Swal.fire({
                    title:'Login!',
                    text: 'non abbiamo nessun account abilitato con i dati inseriti, riprova',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#6993FF',
                    cancelButtonColor: '#dadada',
                    confirmButtonText: 'Ok, riprovo',
                    allowOutsideClick: false,
                 }).then((result) => {
                    if (result.isConfirmed) {

                    }
                });
            }
        });
    }

    logout() {
        localStorage.clear();
        location.href="/";
    }

      showPassword() {
        if(this.showPasswordText==true)
            this.showPasswordText=false;
        else
           this.showPasswordText=true;
      }

      checkLoginSmall(){
        this.showPassword();

        let usernameForm=this.username.nativeElement.value;
        let passwordForm=this.password.nativeElement.value;

        this.crudService.api_Login(usernameForm, passwordForm).subscribe(responseData => {
            //console.log(responseData);
            if(responseData['status']) {
                localStorage.setItem("user_id",JSON.stringify(responseData));
                document.getElementById('btnLogin').style.backgroundColor="#000";

                Swal.fire({
                    title:'Account Verificato!',
                    text: 'login effettuato con successo, accedi ai tuoi corsi.',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: 'var(--mainColor)',
                    cancelButtonColor: '#dadada',
                    confirmButtonText: 'Iniziamo',
                    allowOutsideClick: false,
                 }).then((result) => {
                    if (result.isConfirmed) {
                        location.href="/elenco-corsi/i-miei-corsi";
                    }
                });
            }
            else {
                Swal.fire({
                    title:'Login!',
                    text: 'non abbiamo nessun account abilitato con i dati inseriti, riprova',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: 'var(--mainColor)',
                    cancelButtonColor: '#dadada',
                    confirmButtonText: 'Ok, riprovo',
                    allowOutsideClick: false,
                 }).then((result) => {
                    if (result.isConfirmed) {

                    }
                });
            }
        });
    }

}
